@import '../../../../scss/theme-bootstrap';

.mpp-grid {
  .mixitup-initialized {
    .product-grid__item {
      display: none;
    }
  }
  &__wrapper {
    @include pie-clearfix;
    display: flex;
    flex-wrap: wrap;
    margin: 0 20px;
  }
  &__item {
    display: none; // this is required for mixItUp animation / transitions
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    > .block {
      width: 100%;
    }
    @include breakpoint($medium-up) {
      width: 50%;
      &--colspan-2 {
        width: 100%;
      }
      &--colspan-3 {
        width: 100%;
      }
    }
    @include breakpoint($large-up) {
      width: 33.33%;
      &--colspan-2 {
        width: 66.67%;
      }
      &--colspan-3 {
        width: 100%;
      }
    }
  }
}
